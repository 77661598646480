import { template as template_8e29194147d649578973884bb6b9f902 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_8e29194147d649578973884bb6b9f902(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
