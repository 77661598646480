import { template as template_1c5570671e354ae5a12bdeaa3f3ce2af } from "@ember/template-compiler";
const FKLabel = template_1c5570671e354ae5a12bdeaa3f3ce2af(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
